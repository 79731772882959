import { useState } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import Button from '@mui/material/Button';

import Loading from 'components/UI/Loading/Loading';
import DialogMessage from 'components/UI/DialogMessage/DialogMessage';

import classes from './MemoriesCollage.module.css';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`
  };
}

const MemoriesCollage = props => {
  const [showDialog, setShowDialog] = useState(false);

  const openModalHandler = () => {
    setShowDialog(true);
  };

  const closeDialogHandler = () => {
    setShowDialog(false);
  };

  return (
    <>
      <ul className={classes.collageContainer}>
        {props.lstMemory != null ? (
          props.lstMemory.length > 0 &&
          props.lstMemory.map(memory => (
            <li
              className={classes.imageContainer}
              key={memory.memoryId}
              cols={memory.cols || 1}
              rows={memory.rows || 1}
            >
              <Button
                className={classes.linkMemory}
                component="a"
                href={
                  props.tributId !== 73
                    ? `/tribut/${props.tributId}/memories/${memory.memoryId}`
                    : `/alvaro/memories/${memory.memoryId}`
                }
              >
                <img
                  className={classes.itemCollage}
                  onClick={
                    memory.mediaType === 'mp4' && !memory.thumbnail
                      ? openModalHandler
                      : props.seeMemory(memory.memoryId)
                  }
                  {...srcset(
                    memory.thumbnail,
                    121,
                    memory.rows,
                    memory.cols
                  )}
                  alt={memory.mediaType}
                  loading="lazy"
                />
                <aside className={classes.typeFile}>
                  {memory.mediaType === 'mp4' ||
                    memory.mediaType === 'quicktime' ||
                    (memory.mediaType === 'HLS' && (
                      <MovieOutlinedIcon
                        className={classes.infoIcon}
                      />
                    ))}
                </aside>
                <aside className={classes.moreInfoContainer}>
                  <p className={classes.info}>
                    <ThumbUpOutlinedIcon
                      className={classes.infoIcon}
                    />{' '}
                    {memory.countLikes}
                  </p>
                  <p className={classes.info}>
                    <ForumOutlinedIcon
                      className={classes.infoIcon}
                    />{' '}
                    {memory.comments.length}
                  </p>
                </aside>
              </Button>
            </li>
          ))
        ) : (
          <Loading />
        )}
      </ul>
      <DialogMessage
        closeDialog={closeDialogHandler}
        video
        title={'Video en proceso'}
        open={showDialog}
        message={
          'La publicación del video está en proceso.  No cambies la pantalla y espera un momento por favor.'
        }
      />
    </>
  );
};

MemoriesCollage.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  lstMemory: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  seeMemory: PropTypes.func
};

export default MemoriesCollage;
